import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Level } from 'hudl-uniform-ui-components';
import levelsPreviewData from '../../../../data/previews/levels.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import styles from './styles/level.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Level"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Themes" tierThree="Level" />

      <PageNavigation>
        <PageNavigationLink>Type</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Type">
        <SectionSubhead>Base</SectionSubhead>
        <Paragraph>Use level base colors to segment a layout.</Paragraph>

        <ComponentPreview
          name="ThemesBases"
          layout="default"
          previewData={levelsPreviewData.bases}>
          <Level className={styles.basePreviewWrapper}>
            <div className={styles.basePreview}>Base</div>
          </Level>
        </ComponentPreview>

        <SectionSubhead>Accent</SectionSubhead>
        <Paragraph>
          Use the accent color <strong>only</strong> on its respective base
          color.
        </Paragraph>
        <ComponentPreview
          name="ThemesAccents"
          layout="default"
          previewData={levelsPreviewData.accents}>
          <Level type="base" className={styles.accentsBasePreviewWrapper}>
            <div className={styles.accentsBasePreview}>Base</div>
          </Level>
          <Level type="accent" className={styles.accentsAccentPreviewWrapper}>
            <div className={styles.accentsAccentPreview}>Accent</div>
          </Level>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>How to Use</SectionSubhead>
        <Paragraph>
          Start with Level 0 and build up one level at a time.
        </Paragraph>
        <img
          src={require('../../../../imgs/diagrams/levels-build.gif')}
          className="uni-margin--two--btm"
        />

        <SectionSubhead>Primary Region</SectionSubhead>
        <Paragraph>
          The primary region of the screen should be Level 0, even if that
          region is in the middle of the screen.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use higher levels for primary content regions."
            img="themes-levels-dont"
          />
          <DontDoItem
            type="do"
            text="use Level 0 for primary content regions."
            img="themes-levels-do"
          />
        </DontDo>

        <SectionSubhead>Levels and Layering</SectionSubhead>
        <Paragraph>
          Levels are background colors for two-dimensional regions on the same
          plane as their peers — they do not cast shadows by default. See our{' '}
          <Link href="/guidelines/layering/design">layering guidelines</Link>{' '}
          for more on shadows.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="add shadows to regions unless the element is layered."
            img="themes-layers-dont"
          />
          <DontDoItem
            type="do"
            text="keep levels on the same elevation."
            img="themes-layers-do"
          />
        </DontDo>

        <SectionSubhead>Base and Accents</SectionSubhead>
        <Paragraph>
          Each level has one accent color. An accent color should only be used
          with its respective base color.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="pick accents from any level."
            img="themes-matchingaccents-dont"
          />
          <DontDoItem
            type="do"
            text="use the accent matching the region’s base color."
            img="themes-matchingaccents-do"
          />
        </DontDo>

        <SectionSubhead>Visual Enhancements</SectionSubhead>
        <Paragraph>
          Accents should only be used for subtle graphics like fine lines or
          small regions.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use accents as the background for a large region."
            img="themes-accents-dont"
          />
          <DontDoItem
            type="do"
            text="use accent colors for visual enhancements."
            img="themes-accents-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="Stick to the guidelines above for all platforms.">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
